<template>
  <div class="row mt-4">
    
    <!--LOGS-->
    <ed-modal v-model="bind.boolExibirLog" width="70%">
      <div slot="title">Logs</div>

      <div class="" slot="content">
        <ed-log :formData="formData"/>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Esocial/Esocial"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :disabledFilters="false"
      :disabledSearchRows="boolDesabilitarFiltros"
      @search="getEsocial"
      @filter="getEsocial"
      @delete="$emit('delete',$event)"
      disabledRegisterRows
      disabledEditRows
      :boolFiltroAberto="!boolDesabilitarFiltros"
      :config="{ registerName: 'Gerar XML dos Evento' }"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          label="Data da Ocorrência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
          v-model="formFiltros.strEvento"
          label="Evento"
          name="strEvento"
          itemValue="strCodigo"
          :items="bind.arrayEventos"
          :disabled="intEsocialLoteId ? true : false"
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intCadastroGeralEmpresaId"
          label="Empresa"
          name="intCadastroGeralEmpresaId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{ intTipoCadastroGeralId: 58 }"
          :disabled="intEsocialLoteId  || empresa? true : false"
          :itemList="empresa ? [empresa] : []"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intFuncionarioId"
          label="Funcionário"
          name="intFuncionarioId"
          item-text="strNome"
          route="Cadastro/Funcionario"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralEmpresaId,
          }"
          :itemList="funcionario ? [funcionario] : []"
          :disabled="funcionario ? true : false"
          clearable
        />

        <ed-input-tipo
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayStatus"
          label="Status"
          name="arrayStatus"
          tipo="1383"
          clearable
          multiple
        />
      </template>
      <template slot="table-filter-button" v-if="!intEsocialLoteId">
        <ed-button
          label="Gerar XML dos Eventos"
          :iconLeft="$utilidade.getIcone('xml')"
          @click="gerarXml"
          :disabled="!arrayRows.length"
          color="warning"
        />
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputTipo,
  EdInputSelect,
  EdInputDate,
  EdButton,
} from "@/components/common/form";
import EdLog from './partials/log'

export default {
  name: "pageCadastroEmpresa",
  props: {
    strEvento: {
      type: [String],
      required: false,
      default: null,
    },
    boolDesabilitarFiltros: {
      type: [Boolean],
      required: false,
      default: false,
    },
    intEsocialLoteId:{
      type: [String,Number],
      required: false,
      default: null,
    },
    funcionario:{
      type: [Object],
      required: false,
      default: null,
    },
    empresa:{
      type: [Object],
      required: false,
      default: null,
    }
  },
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputTipo,
    EdInputSelect,
    EdInputDate,
    EdButton,
    EdLog
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {
        boolExibirLog: false,
        intTotalFiltrosApliados: 1,
        arrayEventos: [],
      },
      formData: {},
      formFiltros: {
        evento: null,
        strEvento: this.strEvento,
        intEsocialLoteId:this.intEsocialLoteId,
        intFuncionarioId: this.funcionario ? this.funcionario.intId : null,
        intCadastroGeralEmpresaId: this.empresa ? this.empresa.intId : null,
        arrayDataCadastro: [],
        arrayStatus: [],
      },
      arrayHeaders: [
        {
          text: "Evento",
          sortable: true,
          value: "strEvento",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
        {
          text: "Funcionário",
          sortable: true,
          value: "strNomeFuncionario",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNomeEmpresa",
        },
        {
          text: "OBSERVAÇÕES",
          sortable: true,
          value: "strObservacao",
        },
        {
          text: "Status",
          sortable: true,
          value: "strStatus",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {
    "formFiltros.strEvento"() {},
  },
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Esocial/Esocial", { boolInicializar: true })
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.bind.arrayEventos = objResult.result.arrayEventos;
            this.getEsocial();
          }
        });
    },

    downloadXml(data) {
      var contents = data.strXml;
      var filename = "XML_" + data.intId + ".xml";
      //var contentType = "application/xml";

      //console.log('contents',contents)
      //var blob = this.$utilidade.b64toBlob(contents, contentType);

      this.$utilidade.download(contents, filename);
    },

    getEsocial() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Esocial/Esocial", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {

              let strObservacao = "" ;
              let strStatus =
                '<span title="' +
                (objModel.tipo_status.strValor
                  ? objModel.tipo_status.strValor.strObservacao
                  : "") +
                '" style="color:' +
                (objModel.tipo_status.strValor
                  ? objModel.tipo_status.strValor.strCor
                  : "") +
                '">' +
                (objModel.tipo_status.strValor.strIcone
                  ? '<i class="' +
                    objModel.tipo_status.strValor.strIcone +
                    '"></i> '
                  : "") +
                objModel.tipo_status.strNome +
                "</span>";
              let strDataCadastro = objModel.point.strDataCadastro;

              if (objModel.strPointType == "App\\Models\\Tenant\\Consulta") {
                strObservacao =
                  '<a href="consulta/editar/' +
                  objModel.intPointId +
                  '" target="_blank"> CONSULTA Nº ' +
                  objModel.intPointId +
                  "</a>";

                if (objModel.point && objModel.point.sub_grupo) {
                  strObservacao +=
                    '<span class="ed-full-width ed-table-info">' +
                    objModel.point.sub_grupo.strNome +
                    (objModel.point.intMeses
                      ? " " + objModel.point.intMeses + " MESES"
                      : "") +
                    "</span>";
                }
                strDataCadastro = objModel.point.strDataConsulta;
              } else if (
                objModel.strPointType ==
                "App\\Models\\Tenant\\CadastroGeralSetor"
              ) {

                strObservacao =
                  '<a href="gro/editar/' +
                  objModel.intCadastroGeralEmpresaId +'/'+objModel.intCadastroGeralUnidadeId+
                  '" target="_blank"> SETOR ' +
                  objModel.point.strNome+
                  "</a>";
              }else if (
                objModel.strPointType =="App\\Models\\Tenant\\Absenteismo"
              ) {
                strObservacao =
                  '<a href="absenteismo/editar/' +objModel.intPointId+'" target="_blank"> CAT Nº ' + objModel.intPointId+"</a>";
              }

              let item = {
                intId: objModel.intId,
                strEvento: objModel.strEvento,
                strNomeEmpresa:
                  '<a href="cadastro/empresa/editar/' +
                  objModel.intCadastroGeralEmpresaId +
                  '" target="_blank">' +
                  objModel.empresa.strNome +
                  "</a>",
                strNomeFuncionario: objModel.funcionario
                  ? '<a href="cadastro/funcionario/editar/' +
                    objModel.intFuncionarioId +
                    '" target="_blank">' +
                    objModel.funcionario.strNome +
                    "</a>"
                  : "---",
                strStatus: strStatus,
                strObservacao: strObservacao ? strObservacao : "---",
                strDataCadastro: this.$utilidade.toDate(strDataCadastro, true),
                buttons: [],
                _item: objModel,
              };

              if (objModel.strXml) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("download"),
                  color: "success",
                  title: "Clique para baixar o XML",
                  click: this.downloadXml,
                });
              }

              if (objModel.strLog && objModel.strLog.length) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("risco")+' faa-flash animated',
                  color: "warning",
                  title: "Clique para visualizar os logs do arquivo",
                  click: this.visualizarLog,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    visualizarLog(data) {
      this.formData = data 
      this.bind.boolExibirLog = true
    },

    gerarXml() {
      this.$root.$dialog
        .confirm("Tem certeza que deseja gerar o XML para os eventos da lista?")
        .then((boolExcluir) => {
          this.$root.$dialog.loading(true);

          this.$root.$request
            .post("Esocial/Esocial", this.formFiltros)
            .then((objResult) => {
              this.$root.$dialog.loading(false);

              if (objResult.status == 200) {
                this.getEsocial();

                if (objResult.result) {
                  let strMsg = "<b>Informações dos Eventos Gerados</b><br><br>";
                  strMsg +=
                    "Total de eventos: " +
                    objResult.result.intTotalEventos +
                    "<br>";
                  strMsg +=
                    "Total de eventos gerados: " +
                    objResult.result.intTotalEventosGerados +
                    "<br>";
                  strMsg +=
                    "Total de eventos com falha: " +
                    objResult.result.intTotalEventosFalha +
                    "<br><br>";

                  let boolWarning = false;

                  if (
                    objResult.result.intTotalEventosGerados <
                    objResult.result.intTotalEventos
                  ) {
                    boolWarning = true;
                    strMsg +=
                      "Verifique o status dos eventos para visualizar o detalhe de cada falha";
                  } else {
                    strMsg += "Processo finalizado com sucesso";
                  }

                  if (boolWarning) {
                    this.$root.$dialog.warning(strMsg);
                  } else {
                    this.$root.$dialog.success(strMsg);
                  }
                }
              }
            });
        });
    },
  },
};
</script>
