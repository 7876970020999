<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-vinculo">
        Empresa
        <i :class="$utilidade.getIcone('empresa') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-convenio">
        Convênio
        <i :class="$utilidade.getIcone('convenio') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-pcmso">
        Funções no PCMSO
        <i :class="$utilidade.getIcone('pcmso') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-historico">
        Histórico de Consultas
        <i :class="$utilidade.getIcone('consulta') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-esocial">
        Histórico de envios no eSocial
        <i :class="$utilidade.getIcone('esocial') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        :formData="formData"
        v-if="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />
      <vinculo
        :formData="formData"
        v-if="strTab == 'tab-vinculo' && formData.intId"
        :funcionario="formData"
        :key="'vinculo-' + $root.$session.versao"
      />

      <convenio
        :formData="formData"
        v-if="strTab == 'tab-convenio'"
        :key="'convenio-' + $root.$session.versao"
      />

      <historico-consulta
        v-if="strTab == 'tab-historico' && formData.intId > 0"
        :intFuncionarioId="formData.intId"
        :key="'historico-' + $root.$session.versao"
      />

      <historico-esocial
        v-if="strTab == 'tab-esocial' && formData.intId > 0"
        :funcionario="formData"
        :empresa="formData.empresa"
        :key="'esocial-' + $root.$session.versao"
        boolDesabilitarFiltros
      />

      <pcmso
        v-if="strTab == 'tab-pcmso' && formData.intId > 0"
        :funcionario="formData"
        :intCadastroGeralSetorId="formData.intCadastroGeralSetorId"
        :key="'pcmso-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral.vue";
import Vinculo from "./partials/vinculo.vue";
import Convenio from "./partials/convenio.vue";
import HistoricoConsulta from "@/components/consulta/partials/historico.vue";
import HistoricoEsocial from "@/components/esocial/index.vue";
import Pcmso from "@/components/consulta/partials/pcmso.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncionarioId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    Vinculo,
    Convenio,
    HistoricoConsulta,
    HistoricoEsocial,
    Pcmso
  },
  mounted() {
    this.getFuncionario();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intCadastroGeralSetorId: null,
        intCadastroGeralUnidadeId: null,
        intTipoSexoId: null,
        intFuncaoId: null,
        strNome: null,
        strCpf: null,
        strRg: null,
        strNit: null,
        strCodigoEsocialCategoria: null,
        strMatricula: null,
        strEmail: null,
        strDataNascimento: null,
        strTelefone: null,
        strCelular: null,
        strObservacao: null,
        boolAtivo: 1,
        empresas: [],
        filiais:[this.$root.$session.filial ? this.$root.$session.filial.id : 0],
        endereco: {
          intId: 0,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        },
      },
    };
  },
  watch: {
    intFuncionarioId() {
      this.getFuncionario();
    },
  },
  computed: {},
  methods: {
    getFuncionario() {
      if (!this.intFuncionarioId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Funcionario", {
          intId: this.intFuncionarioId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {

            let arrayFilial = objResponse.result.filiais;

            objResponse.result.filiais = []

            arrayFilial.forEach(element => {
              objResponse.result.filiais.push(element.intFilialId)
            });

            let formData = Object.assign(objResponse.result, {});

            if (!formData.endereco) {
              formData.endereco = {
                intId: 0,
                strCidade: null,
                intCep: null,
                strEstado: null,
                strLogradouro: null,
                strNumero: null,
                strComplemento: null,
                strBairro: null,
              };
            }

            if (!formData.empresas) {
              formData.empresas = [];
            }
            this.formData = formData;
          }
        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/Funcionario", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name != "cadastro.funcionario.edit") {
              this.$router.push({
                name: "cadastro.funcionario.edit",
                params: {
                  intId: objResponse.result.intId,
                },
              });
            } else {
              this.getFuncionario();
            }
          }
        });
    },
  },
};
</script>
