<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroConvenio" width="65%">
      <div slot="title">
        {{ formDataFuncionario.intId ? "Editar convênio" : "Inserir novo convênio " }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataFuncionario" :handlerSave="salvar" disableFixedSave>
          <div class="row">
            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataFuncionario.intConvenioId"
              name="intConvenioId"
              item-text="strNome"
              label="Convênio"
              route="Cadastro/Convenio"
              rules="required"
              @selected="setConvenio"
            />

            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formDataFuncionario.intConvenioPlanoId"
              label="Plano"
              name="intConvenioPlanoId"
              rules="required"
              :disabled="!formDataFuncionario.intConvenioId"
              :items="
                formDataFuncionario.convenio ? formDataFuncionario.convenio.planos : []
              "
              @selected="setPlano"
            />

            <ed-input-text-area
              label="Observações"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              strNome="strObservacao"
              v-model="formDataFuncionario.strObservacao"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo convênio' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputSelect,
  EdInputTextArea,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputAutoComplete,
    EdInputSelect,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroConvenio: false,
      arrayHeaders: [
        {
          text: "Convênio",
          sortable: true,
          value: "strConvenio",
        },
        {
          text: "Plano",
          sortable: true,
          value: "strPlano",
        },
        {
          text: "Observações",
          sortable: true,
          collapse: 100,
          value: "strObservacao",
        },
      ],
      arrayRows: [],
      arrayPlano: [],
      formDataFuncionario: {
        intId: 0,
        inConvenioId: null,
        inConvenioPlanoId: null,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.convenios) {
        this.formData.convenios = [];
      }

      let arrayRows = [];

      this.formData.convenios.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            strConvenio: objModel.convenio.strNome,
            strPlano: objModel.plano.strNome,
            strObservacao: objModel.strObservacao,
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroConvenio = !this.boolExibirCadastroConvenio;

      this.formDataFuncionario = {
        intId: 0,
        inConvenioId: null,
        inConvenioPlanoId: null,
        convenio: null,
        plano: null,
      };
    },

    onEdit(data) {
      this.formDataFuncionario = _.cloneDeep(data);
      this.boolExibirCadastroConvenio = !this.boolExibirCadastroConvenio;

      this.$forceUpdate();
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.convenios.splice(indexCadastroEmail, 1);
        }
      });
    },

    setConvenio(obj) {
      if (obj) {
        this.formDataFuncionario.convenio = obj;
      }
    },

    setPlano(obj) {
      if (obj) {
        this.formDataFuncionario.plano = obj;
      }
    },

    getIndexByIntId(intId) {
      return this.formData.convenios.findIndex((c) => c.intId == intId);
    },

    salvar() {
      console.log("formDataFuncionario", this.formDataFuncionario);
      if (this.formDataFuncionario.intId) {
        let intIndexCadastroGeralEmail = this.getIndexByIntId(
          this.formDataFuncionario.intId
        );

        this.formData.convenios[intIndexCadastroGeralEmail] = Object.assign(
          this.formDataFuncionario,
          {}
        );
      } else {
        this.formData.convenios.push({
          intId: (this.formData.convenios.length + 1) * -1,
          intFuncionarioId: this.formData.intId,
          intConvenioId: this.formDataFuncionario.intConvenioId,
          intConvenioPlanoId: this.formDataFuncionario.intConvenioPlanoId,
          convenio: this.formDataFuncionario.convenio,
          plano: this.formDataFuncionario.plano,
          strObservacao: this.formDataFuncionario.strObservacao,
        });
      }

      this.initialize();
      this.boolExibirCadastroConvenio = !this.boolExibirCadastroConvenio;
    },
  },
};
</script>
