<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-file
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 "
          v-model="formData.arquivo_foto"
          name="intArquivoFotoId"
          label="Foto"
          type="image"
          accept="image/*"
          :url="getUrlFoto()"
        />
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <div class="row ">

            <ed-input-select
              v-if="$root.$session.filiais.length>1"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
              v-model="formData.filiais"
              label="Filial"
              name="filiais"
              rules="required"
              :items="$root.$session.filiais"
              multiple
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formData.strNome"
              name="strNome"
              label="Nome"
              rules="required"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formData.strCpf"
              name="strCpf"
              label="CPF"
              v-mask="'999.999.999-99'"
              rules="required|cpf"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formData.strRg"
              name="strRg"
              label="RG"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formData.strNit"
              name="strNit"
              label="NIT"
              rules="pis"
              v-mask="'999.99999.99-9'"
            />

            <ed-input-tipo
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
              v-model="formData.intTipoSexoId"
              name="intTipoSexoId"
              label="Sexo"
              tipo="74"
              rules="required"
            />
          </div>
        </div>
        
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strDataNascimento"
          name="strDataNascimento"
          label="Data de Nascimento"
          rules="required"
          :hint="$utilidade.calcularIdade(formData.strDataNascimento)"
        />
      </div>

      <span class="form-title">Contato</span>
      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strTelefone"
          name="strTelefone"
          label="Telefone"
          v-mask="'(99) 9999-9999'"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strCelular"
          name="strCelular"
          label="Celular"
          v-mask="'(99) 99999-9999'"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strEmail"
          name="strEmail"
          label="Email"
          rules="email"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Endereço</span>
      <ed-endereco :formData="formData" />
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <campos-personalizado
        :formData="formData"
        :key="'campo-' + $root.$session.versao"
        :intTipoModeloId="309"
        @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
      />
    </div>
  </div>
</template>

<script>
import EdEndereco from "@/components/cadastro/geral/partials/endereco";
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";

import {
  EdInputText,
  EdInputFile,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputDate,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputFile,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputSwitch,
    EdInputAutoComplete,
    EdEndereco,
    EdInputDate,
    CamposPersonalizado,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    getUrlFoto() {
      if (this.formData.arquivo_foto && this.formData.arquivo_foto.intId) {
        
        return this.$root.$request.url("Common/Arquivo/get", {
          boolDisplay: 1,
          intId: this.formData.arquivo_foto.intId,
        });
      }
      return null;
    },

    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },
  },
};
</script>
