<template>
  <div v-if="formData">
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#error">
        Erros ({{ getLista("error").length }})
        <i :class="$utilidade.getIcone('fechar') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#warning">
        Pontos de Atenção ({{ getLista("warning").length }})
        <i :class="$utilidade.getIcone('risco') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-simple-table disabledRegisterRows disabledSearchRows>
      <thead>
        <tr>
          <th width="50">#</th>
          <th>Mensagem</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(objError, index) in getLista()" :key="index">
          <td>{{ index + 1 }}</td>
          <td>

            <span
              class="ed-full-width ed-table-info"
              v-if="objError.funcionario"
              style="color: #c97e7e; font-weight: bold"
            >
              {{objError.strIdentificador}} | {{ objError.funcionario.strNome }}
            </span>

            <label v-if="objError.intCodigo">
              {{ objError.intCodigo }} </label
            > {{ objError.strMsg }}

   
            <span
              class="ed-full-width ed-table-info"
              v-if="objError.arrayOcorrencia && objError.arrayOcorrencia.length"
              style="color: #c97e7e; font-weight: bold"
            >
              <div
                class="ed-full-width ed-table-info"
                v-for="(objOcorrencia, j) in objError.arrayOcorrencia"
                :key="'ocorrencia-' + j"
              >
                {{ objOcorrencia.intCodigo }} - {{ objOcorrencia.strMsg }}
              </div>
            </span>
            <span
              class="ed-full-width ed-table-info"
              v-if="objError.strNumeroRecibo"
              style="color: #2d9e39; font-weight: bold"
            >
              Número do Recibo: {{ objError.strNumeroRecibo }}
            </span>
            <span
              class="ed-full-width ed-table-info"
              v-if="objError.strAcao"
              style="color: #c97e7e; font-weight: bold"
            >
              <i :class="$utilidade.getIcone('risco')"></i>
              Ação sugerida: {{ objError.strAcao }}
            </span>
          </td>
          <td></td>
        </tr>
        <tr v-if="!getLista().length">
          <td colspan="3" align="center">Nenhuma ocorrência encontrado</td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdSimpleTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdSimpleTable,
  },
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {
      strTab: "error",
    };
  },
  watch: {},
  methods: {},
  computed: {
    getLista() {
      return (strTab) => {
        let array = [];

        if (!strTab) {
          strTab = this.strTab;
        }

        this.formData.strLog.forEach((element) => {
          if (element.strTipo == strTab) {
            array.push(element);
          }
        });

        return array;
      };
    },
  },
};
</script>
